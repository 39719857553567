import Store, { RootState } from "../store/store";

export const TEST_PUNTERS_API: string = "https://mockAxiostesturl.com";
const configConst = (state: RootState) => state.config.data;
export const getConfig = () => {
  const config = configConst(Store.getState());

  if (process.env.NODE_ENV === "test") {
    const testconfig = { ...config };

    testconfig.puntersApi = TEST_PUNTERS_API;
    testconfig.legacyTokenIssuerApi = TEST_PUNTERS_API;
    testconfig.cmsPlatformId = "4";

    return testconfig;
  }

  return config;
};
