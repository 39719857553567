import { useSelector } from "react-redux";
import { Navigate, Outlet, useOutletContext } from "react-router-dom";
import type { RootState } from "./store/store";
import { getCookie } from "./utils/cookieUtils";

const ProtectedRoutes = () => {
  const persistedLoggedin = getCookie("Loggedin");

  const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);

  return isLoggedIn || persistedLoggedin ? (
    <Outlet context={useOutletContext()} />
  ) : (
    <Navigate to="/" />
  );
};

export default ProtectedRoutes;
