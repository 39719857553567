import PuntersLogo from "@assets/pc-logo-long.png";
import PuntersLogoPlaceHolder from "@assets/placeholders/pc-logo-long-ph.png";
import useProgressiveImage from "../../hooks/useProgressiveImage";

interface ILogo {
  width?: string;
  maxwidth?: string;
}

const Logo = ({ width, maxwidth }: ILogo) => {
  const { loadedImage } = useProgressiveImage(
    PuntersLogo,
    PuntersLogoPlaceHolder
  );

  return (
    <img
      src={loadedImage ?? PuntersLogo}
      style={{
        width: `${width ?? "80vw"}`,
        maxWidth: `${maxwidth ?? "350px"}`,
        position: "static"
      }}
    />
  );
};

export default Logo;
