import {
  BetType,
  IBetSlipBet,
  IBetSlipLeg,
  ILimitset
} from "../betslip.models";
import { isAllToBet } from "./betUtils";

export default class Calculations {
  static getLimitReached = (
    betType: BetType,
    payoutPerBet: number,
    firstTimer: boolean,
    limits: { [key: string]: ILimitset }
  ): boolean => {
    if (Object.keys(limits).length > 0) {
      const payoutLimit = firstTimer
        ? limits.firstTimerLimits
        : limits.regularLimits;
      switch (betType) {
        case BetType.SingleWin:
        case BetType.SinglePlace:
          return payoutLimit.single < payoutPerBet;

        case BetType.WinDoubles:
          return payoutLimit.double < payoutPerBet;

        case BetType.WinTrebles:
          return payoutLimit.treble < payoutPerBet;

        case BetType.WinQuads:
          return payoutLimit.quad < payoutPerBet;

        case BetType.AllToPlace:
        case BetType.AllToCome:
          return payoutLimit.multiple < payoutPerBet;

        default:
          return true;
      }
    }
    return false;
  };

  static getCalculatedOdds = (oddsList: number[]) =>
    oddsList
      .map((odd) => odd + 1)
      .reduce((firstOdd, secondOdd) => firstOdd * secondOdd) - 1;

  static getOddsFromLegs = (legs: IBetSlipLeg[], isPlace: boolean) => {
    const enabledLegs = legs.filter((leg) => leg.enabled);
    const enabledRaces = enabledLegs.map((leg) => leg.race);
    const finalOdds = enabledRaces.map((item) =>
      isPlace ? item.placeOdds : item.odds
    );

    return finalOdds;
  };

  static getCalculatedOddsSingle = (oddsList: number[], hasSelect: boolean) =>
    hasSelect
      ? oddsList
          .map((odd) => odd)
          .reduce((firstOdd, secondOdd) => firstOdd + secondOdd, 0)
      : 0;

  static allCombinationsUtil = (
    arr: any[],
    dataArr: any[],
    start: number,
    end: number,
    index: number,
    r: number,
    store: any[]
  ) => {
    let temp = [];

    if (index === r) {
      temp = [];
      for (let j = 0; j < r; j++) {
        temp.push(dataArr[j]);
      }
      store.push(temp);
    }
    const tempDataArr = [...dataArr];
    for (let i = start; i <= end && end - i + 1 >= r - index; i++) {
      tempDataArr[index] = arr[i];
      this.allCombinationsUtil(
        arr,
        tempDataArr,
        i + 1,
        end,
        index + 1,
        r,
        store
      );
    }
  };

  static getMultiesOdds = (oddsList: number[], factorial: number) => {
    const currentCombinations: number[] = [];
    const end = oddsList.length - 1;
    const start: number = 0;
    const index: number = 0;
    const store: number[][] = [];

    this.allCombinationsUtil(
      oddsList,
      currentCombinations,
      start,
      end,
      index,
      factorial,
      store
    );

    let totalOdds = 0;

    /* eslint-disable no-restricted-syntax */
    for (const val of store) {
      const odds = Calculations.getCalculatedOdds(val);
      totalOdds += odds;
    }
    /* eslint-enable */
    return totalOdds;
  };

  static getOddsFormultiples = (arrinput: number[]) => {
    let product = 1;
    arrinput
      .map((x) => x + 1)
      .forEach((item) => {
        product *= item;
      });

    return product;
  };

  static combinations = (legs: number, div: number): number =>
    this.factorialDivision(legs, legs - div) / this.factorial(div);

  static getTotalStake(bets: IBetSlipBet[]): number {
    const totalStake = bets
      .filter((bet) => bet.enabled && (!bet.overLimit || !bet.isFixedOdds))
      .map((bet) => this.getTotalStakeForBetType(bet))
      .reduce((stake1, stake2) => stake1 + stake2, 0);

    return Number.isNaN(totalStake) ? 0 : totalStake;
  }

  static getTotalPotentialPayout(bets: IBetSlipBet[]): number {
    const totalPotentialPayout = bets
      .filter((bet) => bet.enabled && (!bet.overLimit || !bet.isFixedOdds))
      .map((bet) => this.getPotentialReturn(bet))
      .reduce((stake1, stake2) => stake1 + stake2, 0);

    return Number.isNaN(totalPotentialPayout) ? 0 : totalPotentialPayout;
  }

  static getPotentialPayout(bet: IBetSlipBet): number {
    if (bet.legs.length > 0 && isAllToBet(bet)) {
      const multiOdds = Calculations.getCalculatedOdds(
        Calculations.getOddsFromLegs(
          bet.legs,
          bet.betType === BetType.AllToPlace
        )
      );

      return multiOdds * bet.stakeToBet;
    }
    return bet.finalOdds * bet.stakeToBet;
  }

  static getPotentialReturn(bet: IBetSlipBet): number {
    const potentialPayout = this.getPotentialPayout(bet);
    if (bet.legs.length > 0 && isAllToBet(bet)) {
      return potentialPayout + bet.stakeToBet;
    }
    return potentialPayout + bet.betCount * bet.stakeToBet;
  }

  static getTotalStakeForBetType(bet: IBetSlipBet): number {
    if (isAllToBet(bet)) {
      return bet.stakeToBet;
    }
    const total = bet.stakeToBet * bet.betCount;
    return total;
  }

  private static factorialDivision(
    topFactorial: number,
    divisorFactorial: number
  ): number {
    let result = 1;
    for (let i = topFactorial; i > divisorFactorial; i--) result *= i;
    return result;
  }

  private static factorial(i: number): number {
    if (i <= 1) return 1;
    return i * this.factorial(i - 1);
  }
}
