/* eslint-disable */
import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import axios from "axios";
import type { RootState } from "../store";

export interface IBetslipBetType {
  deductionPercentageUsed: number | null;
  eventBetTypeDetailMapID: number;
  eventDateTime: string;
  eventDetailID: number;
  eventDetailName: string;
  eventDetailNameExtra: number | null;
  eventDetailNumber: number;
  eventDetailOfferedOdds: number;
  eventID: number;
  eventName: string;
  eventNumber: number;
  fK_EventBetTypeMapID: number;
  fK_EventDetailStatusID: number | null;
  favourite: number | null;
  finishingPosition: number;
  firstTimer: boolean;
  fracOfferredOdds?: string;
}

type betslipBalances = {
  balanceAvailable: number;
  balancePending: number;
  bonusBalance: number;
};

type BetstrikeResponse = {
  response: any[];
  ResponseStatus: number;
  ResponseMessage: string;
  ResponseMessageDetail: string;
};

interface IBetslipData {
  bets: IBetslipBetType[] | null;
  placebets: IBetslipBetType[] | null;
  spbets: IBetslipBetType[] | null;
  balances: betslipBalances | null;
  betstrikeResponse: BetstrikeResponse | null;
  error: string | null;
  isFetching: boolean;
  isLuckyPickOn: boolean;
}

const initialState: IBetslipData = {
  bets: null,
  placebets: null,
  balances: null,
  betstrikeResponse: null,
  error: null,
  spbets: null,
  isFetching: false,
  isLuckyPickOn: false
};

type BetslipRequestData = {
  selection: any;
  punterId?: string | number;
  userName?: string;
  meetingId?: string;
};

const configConst = (state: RootState) => state.config.data;

export const getBetslipData: any = createAsyncThunk(
  "getBetslipData",
  async (
    { selection, punterId, userName, meetingId }: BetslipRequestData,
    { getState }
  ) => {
    const config = configConst(getState() as RootState);

    await axios.get(`${config.puntersApi}/api/betSlip/ClientIn`);
    const clientBalance = await axios.post(
      `${config.puntersApi}/api/betslip/ClientBalance/${userName}`
    );
    const specialBetslip = await axios.post(
      `${config.puntersApi}/api/betSlip/getSpecialBetslip/${punterId}/${meetingId}`,
      selection
    );

    return {
      balances: clientBalance?.data?.clientBalances,
      betslip: specialBetslip.data
    };
  }
);

export type BetPlacementPayloadData = {
  bets: any[];
  singleBet: any;
  isLuckyPick: boolean;
};

export const oldStrikeBet: any = createAsyncThunk(
  "oldStrikeBet",
  async (
    { bets, singleBet, isLuckyPick }: BetPlacementPayloadData,
    { getState }
  ) => {
    const config = configConst(getState() as RootState);

    const url = `${config.puntersApi}/api/Betslip/PlaceBet`.replace("/v2", "");

    const betPlaced = await axios.post(url, { bets, singleBet, isLuckyPick });

    return betPlaced;
  }
);

const BetslipSlice = createSlice({
  name: "betslip",
  initialState,
  reducers: {
    toggleLuckyPick: (state, action) => {
      state.isLuckyPickOn = action.payload ?? !state.isLuckyPickOn;
    }
  },
  extraReducers(builder) {
    builder.addCase(getBetslipData.pending, (state, action) => {
      state.isFetching = true;
      state.error = null;
      state.bets = null;
      state.placebets = null;
      state.spbets = null;
      console.log("@betSlipSlice getBetslipData.pending state");
    });

    builder.addCase(getBetslipData.fulfilled, (state, action) => {
      state.isFetching = false;
      state.bets = action.payload.betslip?.bets;
      state.placebets = action.payload.betslip?.placeBets;
      state.balances = action.payload.balances
        ?.responseObject as betslipBalances;
      state.spbets = action.payload.betslip?.spBets;
      console.log("@betSlipSlice getBetslipData.fulfilled state");
    });
    builder.addCase(getBetslipData.rejected, (state, action) => {
      state.isFetching = false;
      state.error = action.error.message;
      console.log(
        "@betSlipSlice getBetslipData.rejected state ,reason = ",
        action.error.message
      );
    });

    builder.addCase(oldStrikeBet.pending, (state, action) => {
      console.log("@betSlipSlice strikeBet.pending state");
    });

    builder.addCase(oldStrikeBet.fulfilled, (state, action) => {
      console.log(
        "@betSlipSlice strikeBet.fulfilled state: " +
          JSON.stringify(action.payload.data)
      );

      state.betstrikeResponse = JSON.parse(
        action.payload.data.slice(action.payload.data.indexOf("{"))
      );
    });

    builder.addCase(oldStrikeBet.rejected, (state, action) => {
      console.log(
        "@betSlipSlice strikeBet.rejected state ,reason = ",
        action.error.message
      );
    });
  }
});

export const betstrikeResponseSelector = createSelector(
  (state: RootState) => state.betslip,
  (data) => {
    return data.betstrikeResponse;
  }
);

export const { toggleLuckyPick } = BetslipSlice.actions;

export default BetslipSlice.reducer;
