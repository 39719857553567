import React, { useEffect, useState, useContext } from "react";
import { styled, keyframes } from "styled-components";

import FlexContainer from "../../bricks/flexContainer";
import Container from "../../bricks/container";

import { MediaQueriesContext } from "../../../contexts/mediaQueriesContext";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const fadeOut = keyframes`
  from {
    opacity: 1;
   
  }
  to {
    opacity: 0;
   
  }
`;
interface IFadingContainer {
  $fadesin?: boolean;
  $stayTime?: number;
  $fadeTime?: number;
}
const FadingFlexContainer = styled(FlexContainer)<IFadingContainer>`
  animation: ${(props) => (props.$fadesin ? fadeIn : fadeOut)}
    ${(props) => (props.$fadeTime ? `${props.$fadeTime}s` : "0.2s")} linear;
`;
interface IAlertMessage {
  type: string;
  message: string;
  stayTime: number;
  fadeTime: number;
  show: boolean;
}
const AlertMessage = ({
  type,
  message,
  stayTime,
  fadeTime,
  show
}: IAlertMessage) => {
  const [fadesin, setFadesin] = useState(true);

  const fadetime = fadeTime || 200;

  const { isDesktop } = useContext(MediaQueriesContext).data;

  useEffect(() => {
    setTimeout(
      () => {
        setFadesin(false);
      },
      stayTime + fadeTime + fadeTime
    );
  }, []);

  const getColor = () => {
    if (type === "success") return "green";
    if (type === "error") return "red";
    if (type === "warning") return "#cc5200";

    return "green";
  };

  return (
    <FadingFlexContainer
      direction="column"
      width="90%"
      $fadesin={fadesin}
      $fadeTime={fadetime / 1000}
      style={{ opacity: 1, display: `${show ? "" : "none"}` }}>
      <FlexContainer
        direction="column"
        padding="7px"
        position="relative"
        width="96%"
        height="100%"
        borderradius="6px"
        border="1px solid white"
        justifycontent="center"
        minheight={`${isDesktop ? "3vw" : "14vw"}`}
        boxshadow="1px 1px 2px #9494b8">
        <Container
          bgcolor={getColor()}
          position="absolute"
          width="100%"
          height="100%"
          style={{ opacity: 0.9 }}
          borderradius="6px"
          alignitems="top"
        />

        <span style={{ color: "white", position: "relative", lineHeight: 1.2 }}>
          {" "}
          {message}
        </span>
      </FlexContainer>
    </FadingFlexContainer>
  );
};

export default AlertMessage;
