import styled from "styled-components";
import { forwardRef, MouseEvent } from "react";

export interface IContainer {
  children?: any;
  bgcolor?: string;
  width?: string;
  height?: string;
  padding?: string;
  style?: object;
  border?: string;
  borderbottom?: string;
  borderright?: string;
  borderleft?: string;
  bordertop?: string;
  borderradius?: string;
  paddingleft?: string;
  paddingright?: string;
  paddingtop?: string;
  paddingbottom?: string;
  marginleft?: string;
  marginright?: string;
  margintop?: string;
  marginbottom?: string;
  color?: string;
  margin?: string;
  position?: string;
  boxsizing?: string;
  display?: string;
  textalign?: string;
  fontfamlily?: string;
  fontSize?: string;
  fontStyle?: string;
  fontWeight?: string;
  onClick?: () => void;
  onMouseEnter?: (e: MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: () => void;
  lineheight?: string;
  alignitems?: string;
  alignContent?: string;
  left?: string;
  right?: string;
  bottom?: string;
  top?: string;
  overflow?: string;
  placecontent?: string;
  boxshadow?: string;
  ref?: any;
  bordertopleftradius?: string;
  bordertoprightradius?: string;
  borderbottomrightradius?: string;
  borderbottomleftradius?: string;
  minwidth?: string;
  maxwidth?: string;
  minheight?: string;
  maxheight?: string;
  wordwrap?: string;
  id?: string;
  cursor?: string;
  opacity?: number;
  overflowwrap?: string;
  zIndex?: number;
  backgroundImage?: string;
  backgroundSize?: string;
  backgroundRepeat?: string;
  backgroundPosition?: string;
  scrollbarWidth?: string;
  mask?: string;
  transform?: string;
  justifyContent?: string;
  gap?: number;
  textDecoration?: string;
}

const getBackground = (props: any): string => {
  let chosenCoolor =
    props.bgcolor?.length &&
    props.bgcolor !== "none" &&
    (props.theme.colors[props.bgcolor] ?? props.bgcolor);
  if (props.bgcolor === "none") chosenCoolor = "none";

  return chosenCoolor ?? props.theme.colors.bodyPrimary;
};

const getColor = (props: any): string =>
  props.theme.colors[props.color] ?? props.color ?? "inherit";
export const StyledContainer = styled.div<IContainer>`
  id: ${(props) => props.id ?? ""};
  background: ${(props) => (props.bgcolor ? getBackground(props) : "inherit")};
  width: ${(props) => props.width ?? "auto"};
  max-width: ${(props) => props.maxwidth ?? "auto"};
  min-width: ${(props) => props.minwidth ?? "auto"};
  min-height: ${(props) => props.minheight ?? "auto"};
  max-height: ${(props) => props.maxheight ?? "auto"};
  height: ${(props) => props.height ?? "auto"};
  position: ${(props) => props.position ?? "static"};
  padding: ${(props) => props.padding ?? "0 auto"};
  padding-left: ${(props) => props.paddingleft ?? props.padding ?? "0px"};
  padding-right: ${(props) => props.paddingright ?? props.padding ?? "0px"};
  padding-top: ${(props) => props.paddingtop ?? props.padding ?? "0px"};
  padding-bottom: ${(props) => props.paddingbottom ?? props.padding ?? "0px"};
  margin: ${(props) => props.margin ?? "0"};
  margin-left: ${(props) => (props.marginleft ? props.marginleft : "0px")};
  margin-right: ${(props) =>
    props.marginright ?? (props.margin ? props.margin : "0px")};
  margin-top: ${(props) =>
    props.margintop ?? (props.margin ? props.margin : "0px")};
  margin-bottom: ${(props) =>
    props.marginbottom ?? (props.margin ? props.margin : "0px")};
  color: ${(props) => getColor(props)};
  border: ${(props) => props.border ?? "0px"};
  border-right: ${(props) => props.borderright ?? props.border ?? "0px"};
  border-bottom: ${(props) => props.borderbottom};
  border-radius: ${(props) => props.borderradius ?? "0px"};
  border-left: ${(props) => props.borderleft ?? props.border ?? "0px"};
  border-top: ${(props) => props.bordertop ?? props.border ?? "0px"};
  box-sizing: ${(props) => (props.boxsizing ? "border-box" : "none")};
  text-align: ${(props) => props.textalign ?? props.theme.font.align.default};
  display: ${(props) => props.display ?? "block"};
  font-family: ${(props) => props.fontfamlily ?? props.theme.font.family.base};
  font-style: ${(props) => props.fontStyle ?? props.theme.font.style.normal};
  font-size: ${(props) => props.fontSize ?? "inherit"};
  font-weight: ${(props) => props.fontWeight ?? "inherit"};
  line-height: ${(props) => props.lineheight ?? props.theme.font.lineHeight};
  align-items: ${(props) => props.alignitems ?? "normal"};
  align-content: ${(props) => props.content ?? "normal"};
  top: ${(props) => props.top ?? "0"};
  bottom: ${(props) => props.bottom ?? "0"};
  left: ${(props) => props.left ?? "0"};
  right: ${(props) => props.right ?? "0"};
  overflow: ${(props) => props.overflow ?? "visible"};
  place-content: ${(props) => props.placecontent ?? "normal"};
  box-shadow: ${(props) => props.boxshadow ?? "none"};
  border-top-left-radius: ${(props) =>
    props.bordertopleftradius ?? props.borderradius ?? "0"};
  border-top-right-radius: ${(props) =>
    props.bordertoprightradius ?? props.borderradius ?? "0"};
  border-bottom-right-radius: ${(props) =>
    props.borderbottomrightradius ?? props.borderradius ?? "0"};
  border-bottom-left-radius: ${(props) =>
    props.borderbottomleftradius ?? props.borderradius ?? "0"};
  word-wrap: ${(props) => props.wordwrap ?? "normal"};
  cursor: ${(props) => props.cursor ?? "auto"};
  opacity: ${(props) => props.opacity ?? 1};
  overflow-wrap: ${(props) => props.overflowwrap ?? "normal"};
  z-index: ${(props) => props.zIndex ?? "auto"};
  background-image: ${(props) => props.backgroundImage};
  background-position: ${(props) => props.backgroundPosition ?? "0% 0%"};
  background-size: ${(props) => props.backgroundSize ?? "auto"};
  background-repeat: ${(props) => props.backgroundRepeat ?? "repeat"};
  scrollbar-width: ${(props) => props.scrollbarWidth ?? "auto"};
  mask: ${(props) => props.mask};
  transform: ${(props) => props.transform ?? "none"};
  justify-content: ${(props) => props.justifyContent ?? "center"};
  gap: ${(props) => props.gap ?? 0}px;
  text-decoration: ${(props) => props.textDecoration ?? "none"};
`;

export const PointerContainer = styled(StyledContainer)`
  width: 60%;
  text-align: left;
  cursor: pointer;
`;

const Container = forwardRef((props: IContainer, ref) => {
  if (
    props.cursor === "pointer" &&
    props.width === "60%" &&
    props.textalign === "left"
  ) {
    return (
      <PointerContainer ref={ref} {...props} style={{ ...props.style }}>
        {props.children}
      </PointerContainer>
    );
  }
  return (
    <StyledContainer ref={ref} {...props} style={{ ...props.style }}>
      {props.children}
    </StyledContainer>
  );
});
export default Container;
