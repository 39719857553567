import ReactLoading from "react-loading";

export interface ILazyFallback {
  text: string;
  textSize?: string;
  textColor?: string;
  hasTextBackground?: boolean;
}

const defaultprops = {
  fontFamily: "Poppins",
  fontSize: "0.7rem",
  color: "black",
  padding: "1px",
  paddingLeft: "5px",
  paddingRight: "5px",
  background: "#f6f6f6",
  borderRadius: "2px"
};

const LazyFallback = ({
  text,
  textSize,
  textColor,
  hasTextBackground
}: ILazyFallback) => {
  const textStyles = {
    ...defaultprops,
    fontSize: textSize ?? defaultprops.fontSize,
    color: textColor ?? defaultprops.color,
    background: !hasTextBackground ? "transparent" : defaultprops.background
  };

  return (
    <div
      style={{
        width: "100%",
        height: "200px",
        marginTop: "20px",
        marginBottom: "20px",
        display: "grid",
        placeContent: "center"
      }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          rowGap: "5px"
        }}>
        <ReactLoading
          type="spokes"
          color="#8080FF"
          height="100px"
          width="100px"
        />
        <div style={{ ...textStyles }}>{text}</div>
      </div>
    </div>
  );
};

export default LazyFallback;
