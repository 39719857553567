import { useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { MediaQueriesContext } from "../../contexts/mediaQueriesContext";

const MIN_WIDTH_DESKTOP: number = 768;
const MediaQueriesProvider = ({ children }: any) => {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${MIN_WIDTH_DESKTOP}px)`
  });

  const value = useMemo(() => ({ data: { isDesktop } }), [isDesktop]);

  return (
    <MediaQueriesContext.Provider value={value}>
      {children}
    </MediaQueriesContext.Provider>
  );
};

export default MediaQueriesProvider;
