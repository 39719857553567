import { useEffect, useState } from "react";

const useProgressiveImage = (mainImage: string, placeholderImage?: string) => {
  const [loadedImage, setLoadedImage] = useState<string | null>(
    placeholderImage ?? null
  );

  const getBase64FromUrl = async (url: string) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  useEffect(() => {
    const img = getBase64FromUrl(mainImage);
    img.then((data) => {
      setLoadedImage(data as string);
    });
  }, [mainImage]);

  return { loadedImage };
};

export default useProgressiveImage;
