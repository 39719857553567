/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../store";
import { IPunterResults } from "../resultsSlice";
import { IPunterQueryPageLeanInputData } from "../leaderboardSlice";
import {
  ERROR_GENERIC_MESSAGE,
  IDeleteALeagueQueryData,
  IFriendsLeague,
  IFriendsLeagueCreateQueryData,
  IFriendsLeagueJoinALeagueQueryData,
  IFriendsLeagueUpdateALeagueQueryData,
  IGetLeagueLeaderboardDetailsQueryData,
  IGetLeagueMembersQueryData,
  IGetLeagueNotificationsQueryData,
  IGetLeaguesLederBoardQueryData,
  IGetPunterLeaguesQueryData,
  ILeagueCreationResponse,
  ILeagueLeaderboardItem,
  ILeagueStatsDataQueryData,
  TPendingAction,
  IPunterLeague,
  IPunterLeagueResponse,
  IPunterSearchRequest,
  IUpdatePunterLeagueStatusResponse,
  ILeagueNotificationsResponse,
  ILeagueMembersResponse,
  IRemoveALeagueMember,
  IPunterLeaderboardDetailResponse,
  ILeagueStatsMeetingsResponse,
  IPunterInfoFromSearch,
  IPunterLeagueStatsResponse,
  IGetDailyLeaderboardResponse,
  IGetLeagueLeaderboard
} from "./types";

const initialState: IFriendsLeague = {
  myLeagues: null,
  joinedLeagues: [],
  newLeagueCreationData: null,
  shareALeagueData: null,
  joinALeagueData: null,
  currentLeagueToManage: null,
  toDisplayManageForLeague: null,
  currentNotifications: null,
  currentLeagueMembers: null,
  toForceUpdateNotifications: false,
  errorMessageOnRejection: null,
  currentMeetingForLeaderboard: null,
  currentLeagueForLeaderboard: null,
  currentLeaderboardPageData: null,
  currentLeaderboardPunterDetail: null,
  currentLeaderboardTotalPages: 0,
  currentLeaderboardMinPoints: 0,
  currentPageNumber: 0,
  isFetchingLeaderboard: false,
  statsMeetings: null,
  leagueStatsData: null,
  isQueriedLeagueToJoinChecked: false,
  leaderboardMaxPages: 0,
  mainLeaderboardStatusData: null
};

const configConst = (state: RootState) => state.config.data;

/**
 *
 */
export const getPunterLeagues: any = createAsyncThunk(
  "getPunterLeagues",
  async (
    { punterId }: IGetPunterLeaguesQueryData,
    { getState }
  ): Promise<IPunterLeagueResponse> => {
    const config = configConst(getState() as RootState);
    const data = await axios.get(
      `${config.puntersApi}/api/MiniLeagues/GetPunterLeagues/${punterId}?leagueStatus=1`
    );
    return data.data;
  }
);

/**
 *
 */
export const createLeague: any = createAsyncThunk(
  "createLeague",
  async (
    { punterId, leagueName }: IFriendsLeagueCreateQueryData,
    { getState }
  ): Promise<ILeagueCreationResponse> => {
    const config = configConst(getState() as RootState);
    const data = await axios.post(
      `${config.puntersApi}/api/MiniLeagues/CreateLeague`,
      { punterId, leagueName }
    );
    return data.data;
  }
);

const getUrlForUpdatePunterLeagueStatus = (
  state: RootState,
  punterId: number,
  leagueCode: string,
  action?: number
): Promise<{ data: IUpdatePunterLeagueStatusResponse }> => {
  const config = configConst(state);
  const payLoad = action
    ? { punterId, leagueCode, punterLeagueStatus: action }
    : { punterId, leagueCode };

  return axios.post(
    `${config.puntersApi}/api/MiniLeagues/UpdatePunterLeagueStatus`,
    payLoad
  );
};

/**
 *
 */
export const joinALeague: any = createAsyncThunk(
  "joinALeague",
  async (
    { punterId, leagueCode }: IFriendsLeagueJoinALeagueQueryData,
    { getState }
  ): Promise<IUpdatePunterLeagueStatusResponse> => {
    const data = await getUrlForUpdatePunterLeagueStatus(
      getState() as RootState,
      punterId,
      leagueCode
    );
    return data.data;
  }
);
/**
 *
 */
export const updateALeague: any = createAsyncThunk(
  "updateALeague",
  async (
    { punterId, leagueCode, action }: IFriendsLeagueUpdateALeagueQueryData,
    { getState }
  ) => {
    const data = await getUrlForUpdatePunterLeagueStatus(
      getState() as RootState,
      punterId,
      leagueCode,
      action
    );

    return data.data;
  }
);
/**
 *
 */
export const removeALeagueMember: any = createAsyncThunk(
  "removeALeagueMember",
  async (
    { punterId, leagueCode, action }: IFriendsLeagueUpdateALeagueQueryData,
    { getState }
  ) => {
    const config = configConst(getState() as RootState);

    const data = await getUrlForUpdatePunterLeagueStatus(
      getState() as RootState,
      punterId,
      leagueCode,
      action
    );

    const newList = await axios.get(
      `${config.puntersApi}/api/MiniLeagues/GetLeagueTeamMembers/${leagueCode}`
    );

    return { removed: data, updatedList: newList?.data?.leagueMembers };
  }
);

/**
 *
 */
export const approveAJoinRequest: any = createAsyncThunk(
  "approveAJoinRequest",
  async (
    { punterId, leagueCode, action }: IFriendsLeagueUpdateALeagueQueryData,
    { getState }
  ): Promise<IUpdatePunterLeagueStatusResponse> => {
    const data = await getUrlForUpdatePunterLeagueStatus(
      getState() as RootState,
      punterId,
      leagueCode,
      action
    );

    return data.data;
  }
);

/**
 *
 */
export const rejectAJoinRequest: any = createAsyncThunk(
  "rejectAJoinRequest",
  async (
    { punterId, leagueCode, action }: IFriendsLeagueUpdateALeagueQueryData,
    { getState }
  ): Promise<IUpdatePunterLeagueStatusResponse> => {
    const config = configConst(getState() as RootState);
    const data = await axios.post(
      `${config.puntersApi}/api/MiniLeagues/UpdatePunterLeagueStatus`,
      { punterId, leagueCode, punterLeagueStatus: action }
    );

    return data.data;
  }
);
/**
 *
 */
export const exitALeague: any = createAsyncThunk(
  "exitALeague",
  async (
    { punterId, leagueCode, action }: IFriendsLeagueUpdateALeagueQueryData,
    { getState }
  ): Promise<{
    exitData: IUpdatePunterLeagueStatusResponse;
    updatedList: IPunterLeagueResponse;
  }> => {
    const config = configConst(getState() as RootState);
    const exitData = await axios.post(
      `${config.puntersApi}/api/MiniLeagues/UpdatePunterLeagueStatus`,
      { punterId, leagueCode, punterLeagueStatus: action }
    );

    const updatedList = await axios.get(
      `${config.puntersApi}/api/MiniLeagues/GetPunterLeagues/${punterId}?leagueStatus=1`
    );

    return { exitData: exitData.data, updatedList: updatedList.data };
  }
);

/**
 *
 */
export const getLeagueNotifications: any = createAsyncThunk(
  "getLeagueNotifications",
  async (
    { leagueCode, punterId }: IGetLeagueNotificationsQueryData,
    { getState }
  ): Promise<ILeagueNotificationsResponse> => {
    const config = configConst(getState() as RootState);

    const data = await axios.get(
      `${config.puntersApi}/api/MiniLeagues/getLeagueNotifications/${punterId}/${leagueCode}`
    );

    return data.data;
  }
);
/**
 *
 */

export const getLeagueMembers: any = createAsyncThunk(
  "getLeagueMembers",
  async (
    { leagueCode }: IGetLeagueMembersQueryData,
    { getState }
  ): Promise<ILeagueMembersResponse> => {
    const config = configConst(getState() as RootState);

    const data = await axios.get(
      `${config.puntersApi}/api/MiniLeagues/GetLeagueTeamMembers/${leagueCode}`
    );

    return data.data;
  }
);

export const getLeagueLeaderboard: any = createAsyncThunk(
  "getLeagueLeaderboard",
  async (
    {
      leagueCode,
      meetingId,
      pageNumber,
      searchString
    }: IGetLeaguesLederBoardQueryData,
    { getState }
  ): Promise<IGetLeagueLeaderboard> => {
    const config = configConst(getState() as RootState);

    const data = await axios.get(
      `${config.puntersApi}/api/MiniLeagues/GetLeagueLeaderboard/${meetingId}/${leagueCode}/${pageNumber}?searchString=${searchString || ""}`
    );

    return { ...data.data, pageNumber };
  }
);

export const getLeagueLeaderboardDetails: any = createAsyncThunk(
  "getLeagueLeaderboardDetails",
  async (
    { punterId }: IGetLeagueLeaderboardDetailsQueryData,
    { getState }
  ): Promise<IPunterLeaderboardDetailResponse> => {
    const rootState = getState() as RootState;

    const config = configConst(rootState);

    const data = await axios.get(
      `${config.puntersApi}/api/MiniLeagues/GetLeaderboardDetailsForPunter/${rootState.friendsLeague.currentMeetingForLeaderboard?.id}/${punterId}`
    );

    return data.data;
  }
);

export const getPunterInfoFromSearch: any = createAsyncThunk(
  "getPunterInfoFromSearch",
  async (
    { pageId, meetingId, numberPerPage, searchFor }: IPunterSearchRequest,
    { getState }
  ): Promise<IPunterInfoFromSearch> => {
    const config = configConst(getState() as RootState);

    const data = await axios.get(
      `${
        config.puntersApi
      }/api/Leaderboard/GetDailyLeaderboardPaged/${pageId}/${numberPerPage}/${meetingId}?username=${
        searchFor || ""
      }`
    );

    return data.data.dailyLeaderboard.punters;
  }
);

export const getStatsMeetings: any = createAsyncThunk(
  "getStatsMeetings",
  async (_, { getState }): Promise<ILeagueStatsMeetingsResponse> => {
    const rootState = getState() as RootState;
    const config = configConst(rootState);

    const data = await axios.get(
      `${config.puntersApi}/api/MiniLeagues/GetStatsMeetings`
    );

    return data.data;
  }
);

export const getStatsData: any = createAsyncThunk(
  "getStatsData",
  async (
    { punterId, raceCourseId, date }: ILeagueStatsDataQueryData,
    { getState }
  ): Promise<IPunterLeagueStatsResponse> => {
    const rootState = getState() as RootState;
    const config = configConst(rootState);

    const data = await axios.get(
      `${config.puntersApi}/api/MiniLeagues/GetPunterStats/${punterId}/${raceCourseId}/${date}`
    );

    return data.data;
  }
);

export const deleteALeague: any = createAsyncThunk(
  "deleteALeague",
  async (
    { punterId, leagueCode, newStatus }: IDeleteALeagueQueryData,
    { getState }
  ): Promise<{ updatedList: IPunterLeagueResponse }> => {
    const rootState = getState() as RootState;
    const config = configConst(rootState);

    await axios.post(`${config.puntersApi}/api/MiniLeagues/UpdateLeague`, {
      punterOwnerId: punterId,
      leagueCode,
      leagueStatus: newStatus
    });

    const updatedListOfLeagues = await axios.get(
      `${config.puntersApi}/api/MiniLeagues/GetPunterLeagues/${punterId}?leagueStatus=1`
    );

    return { updatedList: updatedListOfLeagues?.data };
  }
);

export const checkHasLeaderboardDataForMeeting: any = createAsyncThunk(
  "checkHasLeaderboardDataForMeeting",
  async (
    { punterId, meetingId }: IPunterQueryPageLeanInputData,
    { getState }
  ): Promise<{
    data: IGetDailyLeaderboardResponse;
    meetingid: string | number;
  }> => {
    const config = configConst(getState() as RootState);
    const data = await axios.get(
      `${config.puntersApi}/api/Leaderboard/GetDailyLeaderboard/${
        punterId || 0
      }/${meetingId}`
    );

    return { data: data.data, meetingid: meetingId };
  }
);

const FriendLeagueSlice = createSlice({
  name: "FriendLeague",
  initialState,
  reducers: {
    resetLeagueCreationData: (state) => {
      state.newLeagueCreationData = null;
    },
    resetJoinALeagueData: (state) => {
      state.joinALeagueData = null;
    },
    shareALeague: (state, action) => {
      if (action.payload) {
        state.shareALeagueData = { leagueToShare: action.payload };
      } else {
        state.shareALeagueData = null;
      }
    },
    displayManageForLeague: (state, action) => {
      state.currentNotifications = null;
      state.toDisplayManageForLeague = action.payload ?? null;

      if (state.toDisplayManageForLeague) {
        state.currentLeagueToManage = state.myLeagues?.find(
          (item) => item.leagueName === state.toDisplayManageForLeague
        )!;
      } else {
        state.currentLeagueToManage = null;
        state.currentNotifications = null;
      }
    },
    reset: (state) => {
      state.myLeagues = null;
      state.joinedLeagues = [];
      state.newLeagueCreationData = null;
      state.shareALeagueData = null;
      state.joinALeagueData = null;
      state.currentLeagueToManage = null;
      state.toDisplayManageForLeague = null;
      state.currentNotifications = null;
      state.currentLeagueMembers = null;
      state.toForceUpdateNotifications = false;
      state.currentMeetingForLeaderboard = null;
      state.currentLeagueForLeaderboard = null;
      state.currentLeaderboardPageData = null;
      state.currentLeaderboardPunterDetail = null;
      state.currentLeaderboardTotalPages = 0;
      state.isFetchingLeaderboard = false;
      state.currentPageNumber = 0;
    },
    openLeaderboard: (state, action) => {
      let indentifiedLeague = state.myLeagues?.find(
        (league) => league.leagueName === action.payload
      )!;
      if (!indentifiedLeague)
        indentifiedLeague = state.joinedLeagues?.find(
          (league) => league.leagueName === action.payload
        )!;

      state.currentLeagueForLeaderboard = action.payload
        ? indentifiedLeague
        : null;
      state.currentLeaderboardTotalPages = 0;
    },
    closeLeaderboard: (state) => {
      state.currentMeetingForLeaderboard = null;
      state.currentLeagueForLeaderboard = null;
      state.currentLeaderboardPageData = null;
      state.currentLeaderboardPunterDetail = null;
      state.currentLeaderboardTotalPages = 0;
      state.isFetchingLeaderboard = false;
      state.currentPageNumber = 0;
    },
    setCurrentMeeting: (state, action) => {
      state.currentMeetingForLeaderboard = action.payload ?? null;
    },
    setIsQueriedLeagueToJoinChecked: (state, action) => {
      state.isQueriedLeagueToJoinChecked = action.payload ?? null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPunterLeagues.pending, (state) => {
      state.errorMessageOnRejection = null;
    });

    builder.addCase(
      getPunterLeagues.fulfilled,
      (state, action: PayloadAction<IPunterLeagueResponse>) => {
        state.myLeagues = action.payload.ownedLeagues;
        state.joinedLeagues = action.payload.joinedLeagues;
      }
    );
    builder.addCase(getPunterLeagues.rejected, (state) => {
      state.errorMessageOnRejection = ERROR_GENERIC_MESSAGE;
    });

    builder.addCase(
      createLeague.pending,
      (
        state,
        action: PayloadAction<ILeagueCreationResponse> &
          TPendingAction<IPunterLeague>
      ) => {
        state.newLeagueCreationData = {
          requestedLeagueToCreate: action.meta.arg.leagueName,
          leagueCreatedJustNow: null,
          errorInLeagueCreation: null,
          createdLeagueCode: null
        };
        state.errorMessageOnRejection = null;
      }
    );

    builder.addCase(
      createLeague.fulfilled,
      (state, action: PayloadAction<ILeagueCreationResponse>) => {
        const {
          responseStatus,
          responseMessage,
          responseMessageDetail,
          createdLeague
        } = action.payload;

        if (
          responseStatus === 1 &&
          responseMessage === "Success" &&
          responseMessageDetail === "Success" &&
          createdLeague.leagueName ===
            state.newLeagueCreationData!.requestedLeagueToCreate
        ) {
          state.newLeagueCreationData!.leagueCreatedJustNow =
            createdLeague.leagueName;
          state.newLeagueCreationData!.createdLeagueCode =
            createdLeague.leagueCode;
        } else {
          state.newLeagueCreationData!.errorInLeagueCreation =
            responseMessageDetail;
        }
      }
    );
    builder.addCase(
      joinALeague.pending,
      (
        state,
        action: PayloadAction<IUpdatePunterLeagueStatusResponse> &
          TPendingAction<IPunterLeague>
      ) => {
        state.joinALeagueData = {
          leagueCode: action.meta.arg.leagueCode,
          joinRequestSuccessful: false,
          errorInJoinLeague: null,
          leagueName: ""
        };
      }
    );

    builder.addCase(
      joinALeague.fulfilled,
      (state, action: PayloadAction<IUpdatePunterLeagueStatusResponse>) => {
        const {
          responseStatus,
          responseMessage,
          leagueName,
          responseMessageDetail,
          errorCode
        } = action.payload;

        if (responseStatus === 1 && responseMessage === "Success.") {
          state.joinALeagueData!.leagueName = leagueName;
          state.joinALeagueData!.joinRequestSuccessful = true;
        } else {
          let errorMsg = "The request to join was not successful";

          if (errorCode === 108 || errorCode === 109 || errorCode === 110) {
            errorMsg = responseMessageDetail;
          }
          state.joinALeagueData!.errorInJoinLeague = errorMsg;
        }
      }
    );

    builder.addCase(joinALeague.rejected, (state) => {
      state.joinALeagueData!.errorInJoinLeague =
        "The request to join was not successful";
    });
    builder.addCase(getLeagueNotifications.pending, (state) => {
      state.toForceUpdateNotifications = false;
      state.errorMessageOnRejection = null;
    });
    builder.addCase(
      getLeagueNotifications.fulfilled,
      (state, action: PayloadAction<ILeagueNotificationsResponse>) => {
        if (state.toDisplayManageForLeague) {
          state.currentNotifications = [...action.payload.leagueNotifications];
        }
      }
    );
    builder.addCase(getLeagueNotifications.rejected, (state) => {
      state.errorMessageOnRejection = ERROR_GENERIC_MESSAGE;
    });

    builder.addCase(getLeagueMembers.pending, (state) => {
      state.currentLeagueMembers = null;
    });

    builder.addCase(
      getLeagueMembers.fulfilled,
      (state, action: PayloadAction<ILeagueMembersResponse>) => {
        if (state.toDisplayManageForLeague) {
          state.currentLeagueMembers = [...action.payload.leagueMembers];
        }
      }
    );

    builder.addCase(
      removeALeagueMember.fulfilled,
      (state, action: PayloadAction<IRemoveALeagueMember>) => {
        const { updatedList } = action.payload;

        state.currentLeagueMembers = [...updatedList];
      }
    );

    builder.addCase(
      approveAJoinRequest.fulfilled,
      (state, action: PayloadAction<IUpdatePunterLeagueStatusResponse>) => {
        const { errorCode, responseMessage, responseStatus } = action.payload;

        if (
          errorCode === 0 &&
          responseMessage === "Success." &&
          responseStatus === 1
        ) {
          state.toForceUpdateNotifications = true;
        }
      }
    );

    builder.addCase(
      rejectAJoinRequest.fulfilled,
      (state, action: PayloadAction<IUpdatePunterLeagueStatusResponse>) => {
        const { errorCode, responseMessage, responseStatus } = action.payload;

        if (
          errorCode === 0 &&
          responseMessage === "Success." &&
          responseStatus === 1
        ) {
          state.toForceUpdateNotifications = true;
        }
      }
    );

    builder.addCase(
      exitALeague.fulfilled,
      (
        state,
        action: PayloadAction<{
          exitData: IUpdatePunterLeagueStatusResponse;
          updatedList: IPunterLeagueResponse;
        }>
      ) => {
        const { exitData, updatedList } = action.payload;

        const { errorCode, responseMessage, responseStatus } = exitData;

        if (
          errorCode === 0 &&
          responseMessage === "Success." &&
          responseStatus === 1
        ) {
          state.myLeagues = updatedList.ownedLeagues;

          state.joinedLeagues = updatedList.joinedLeagues;
        }
      }
    );

    builder.addCase(
      getLeagueLeaderboard.pending,
      (
        state,
        action: PayloadAction<IPunterLeaderboardDetailResponse> &
          TPendingAction<IGetLeaguesLederBoardQueryData>
      ) => {
        state.currentPageNumber = action.meta.arg.pageNumber;
        state.isFetchingLeaderboard = true;
      }
    );

    builder.addCase(
      getLeagueLeaderboard.fulfilled,
      (state, action: PayloadAction<IGetLeagueLeaderboard>) => {
        const {
          responseMessage,
          responseStatus,
          currentLeaderboard,
          maxPages,
          minPoints
        } = action.payload;

        if (responseMessage === "Success." && responseStatus === 1) {
          state.currentLeaderboardPageData = currentLeaderboard;
          state.currentLeaderboardTotalPages = maxPages;
          state.currentLeaderboardMinPoints = minPoints;
        }

        state.isFetchingLeaderboard = false;
      }
    );

    builder.addCase(
      getLeagueLeaderboardDetails.fulfilled,
      (state, action: PayloadAction<IPunterLeaderboardDetailResponse>) => {
        const { responseMessage, responseStatus, punterLeaderboardDetails } =
          action.payload;

        if (responseMessage === "Success." && responseStatus === 1) {
          state.currentLeaderboardPunterDetail = punterLeaderboardDetails;
        }
      }
    );
    builder.addCase(
      getStatsMeetings.fulfilled,
      (state, action: PayloadAction<ILeagueStatsMeetingsResponse>) => {
        const { responseMessage, responseStatus, leagueStatsMeetings } =
          action.payload;

        if (responseMessage === "Success." && responseStatus === 1) {
          state.statsMeetings = leagueStatsMeetings;
        }
      }
    );

    builder.addCase(getPunterInfoFromSearch.pending, (state) => {
      state.currentLeaderboardPageData = [];
      state.isFetchingLeaderboard = true;
    });

    builder.addCase(
      getPunterInfoFromSearch.fulfilled,
      (state, action: PayloadAction<IPunterResults[]>) => {
        const response = action.payload;
        const punterInfo: ILeagueLeaderboardItem[] = [];

        response.forEach((punter: IPunterResults) => {
          punterInfo.push({
            punterId: punter.punterId,
            leagueRank: punter.rank,
            punterAlias: punter.userId,
            meetingPoints: punter.total,
            winners: punter.winners,
            secondPlaces: punter.secondPlace,
            thirdPlaces: punter.thirdPlace
          });
        });

        state.currentLeaderboardPageData = punterInfo;
        state.isFetchingLeaderboard = false;
      }
    );

    builder.addCase(getPunterInfoFromSearch.rejected, (state) => {
      state.currentLeaderboardPageData = [];
      state.isFetchingLeaderboard = false;
    });

    builder.addCase(
      getStatsData.fulfilled,
      (state, action: PayloadAction<IPunterLeagueStatsResponse>) => {
        const { responseMessage, responseStatus, currentPunterLeagueStats } =
          action.payload;

        if (responseMessage === "Success." && responseStatus === 1) {
          state.leagueStatsData = currentPunterLeagueStats;
        }
      }
    );

    builder.addCase(
      deleteALeague.fulfilled,
      (
        state,
        action: PayloadAction<{ updatedList: IPunterLeagueResponse }>
      ) => {
        const { ownedLeagues, joinedLeagues } = action.payload.updatedList;

        state.myLeagues = ownedLeagues;
        state.joinedLeagues = joinedLeagues;
      }
    );

    builder.addCase(
      checkHasLeaderboardDataForMeeting.fulfilled,
      (
        state,
        action: PayloadAction<{
          data: IGetDailyLeaderboardResponse;
          meetingid: string;
        }>
      ) => {
        const { data, meetingid } = action.payload;
        const { punters } = data.dailyLeaderboard;

        if (!state.mainLeaderboardStatusData) {
          state.mainLeaderboardStatusData = {};
        }

        state.mainLeaderboardStatusData[meetingid] = {
          meetingId: meetingid,
          hasResulted: punters?.length > 0
        };
      }
    );
  }
});

export const {
  resetLeagueCreationData,
  shareALeague,
  resetJoinALeagueData,
  displayManageForLeague,
  reset,
  openLeaderboard,
  setCurrentMeeting,
  closeLeaderboard,
  setIsQueriedLeagueToJoinChecked
} = FriendLeagueSlice.actions;

export default FriendLeagueSlice.reducer;
