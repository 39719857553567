import styled from "styled-components";
import FlexContainer from "../components/bricks/flexContainer";
import theme from "../theme";

export const UsefulLinksItemLabel = styled.label`
  width: max-content;
  cursor: pointer;

  @media screen and (max-width: 431) {
    font-size: 10px;
    colour: white;
  }

  @media screen and (min-width: 769px) and (max-width: 800px) {
    font-size: 0.6rem;
  }

  @media screen and (min-width: 801px) and (max-width: 1437px) {
    font-size: 0.7rem;
  }

  @media screen and (min-width: 1438px) {
    font-size: 0.9rem;
  }
`;

export const HeaderUsefulLinks = styled.label`
  width: max-content;
  margin-bottom: 5px;

  @media screen and (min-width: 769px) and (max-width: 1437px) {
    font-size: 0.9rem;
  }

  @media screen and (min-width: 1438px) {
    font-size: 1.2rem;
  }
`;

export const UsefulLinksHeaderMob = styled.label`
  margin-left: 1px;
  margin-bottom: 10px;
  font-size: 16px;
  }
`;

export const UsefulLinksHeaderContainerMob = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 27px;
  width: 90%;
  color: ${theme.colors.primary};
  font-size: 0.7rem;
  align-items: flex-start;
`;

export const DoMoreFlexContainer = styled(FlexContainer)`
  min-width: 200px;
  justify-content: space-between;

  @media screen and (min-width: 769px) and (max-width: 800px) {
    max-width: 210px;
  }

  @media screen and (min-width: 801px) and (max-width: 1139px) {
    max-width: 210px;
  }

  @media screen and (min-width: 1140px) and (max-width: 1419px) {
    max-width: 250px;
  }

  @media screen and (min-width: 1420px) {
    max-width: 300px;
  }
`;

export const GallopTvDiv = styled.img`
  width: 100%;
  display: block;
  background: white;
  border-radius: 3px;
  cursor: pointer;
`;

export const HWBLogoDiv = styled.img`
  margin-left: 1px;
  width: 100%;
  display: block;
  background: white;
  border-radius: 3px;
  cursor: pointer;
`;

export const WinningFormLogoDiv = styled.img`
  margin-left: 1px;
  width: 100%;
  display: block;
  background: white;
  border-radius: 3px;
  cursor: pointer;
`;
