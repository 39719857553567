import React, { useEffect, useState, useRef, useContext } from "react";
import FlexContainer from "../../bricks/flexContainer";
import AlertMessage from "./alertMessage";
import { NotificationContext } from "../../../contexts/notificationContext";

interface TMessageUnit {
  id: string;
  type: string;
  message: string;
  stayTime: number;
  fadeTime: number;
  toShow: boolean;
}

const AlertComopnent = () => {
  const newMessage = useContext(NotificationContext).data;
  const [messagesUnits, setMessagesUnits] = useState<TMessageUnit[]>([]);
  const currentMessages = useRef<any[]>([]);
  currentMessages.current = messagesUnits;
  const count = useRef<number>(0);

  const remove = (unit: TMessageUnit) => {
    const units = currentMessages.current;
    const target: TMessageUnit = units.find(
      (item) => unit.id === item.id
    ) as TMessageUnit;
    target.toShow = false;

    setMessagesUnits([...units]);
  };

  const isNotDuplicate = (type: string, message: string) =>
    messagesUnits.find(
      (item) => item.type === type && item.message === message
    ) === undefined;
  useEffect(() => {
    const { status, message, duration } = newMessage;

    if (message && isNotDuplicate(status as string, message)) {
      const unit: TMessageUnit = {
        id: `${++count.current}${message}`,
        type: status!,
        message,
        stayTime: duration ?? 2000,
        fadeTime: 200,
        toShow: true
      };

      setMessagesUnits([...messagesUnits, unit]);

      const calculatedDuaration =
        unit.stayTime + unit.fadeTime * 2 + unit.fadeTime - 50;

      setTimeout(() => {
        remove(unit);
      }, calculatedDuaration);
    }
  }, [newMessage]);

  useEffect(() => {
    const visible = currentMessages.current.length;
    let count = 0;

    if (messagesUnits.length) {
      messagesUnits.forEach((item) => {
        if (!item.toShow) count += 1;
      });

      if (count === visible) setMessagesUnits([]);
    }
  }, [messagesUnits]);

  return (
    <FlexContainer
      direction="column"
      alignitems="center"
      width="100%"
      height="max-content"
      paddingtop="10px"
      position="absolute"
      rowgap="5px"
      style={{ zIndex: 100 }}>
      {currentMessages.current.length > 0 &&
        currentMessages.current.map((item) => (
          <AlertMessage
            key={`message_${item.message}`}
            message={item.message}
            type={item.type}
            stayTime={item.stayTime}
            fadeTime={item.fadeTime}
            show={item.toShow}
          />
        ))}
    </FlexContainer>
  );
};

export default AlertComopnent;
