import { Outlet, useLocation, useOutletContext } from "react-router-dom";
import { lazy, Suspense, useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Footer from "../layouts/footer";
import AlertComopnent from "../components/views/alert/alertComopnent";
import ErrorHandler from "../components/errorHandler";
import { RootState } from "../store/store";
import useLogin from "../hooks/useLogin";

const HeaderNew = lazy(() => import("../components/views/header/headerNew"));

interface ContextType {
  toShowLogin: boolean;
  toShowLoginUpdater: (show: boolean) => void;
}

const LandingDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Landing = () => {
  const location = useLocation();

  const { onLogInClick } = useLogin();

  const [toShowLogin, setToShowLogin] = useState<boolean>(false);

  const { isLoggedIn, hasCheckedLoggedIn } = useSelector(
    (state: RootState) => state.user
  );

  const toShowLoginUpdater = (show: boolean = true) => {
    setToShowLogin(show);
  };

  const isHomePage = () => {
    return location.pathname === "/" || location.pathname === "/home";
  };

  useEffect(() => {
    const pendingProtectedRoute = localStorage.getItem("pendingProtectedRoute");
    const waitingForLogin = localStorage.getItem("waitingForLogin");

    if (hasCheckedLoggedIn && pendingProtectedRoute) {
      if (isLoggedIn && waitingForLogin === "true") {
        localStorage.removeItem("pendingProtectedRoute");
        localStorage.removeItem("waitingForLogin");
        window.location.replace(pendingProtectedRoute);
      } else if (!isLoggedIn && waitingForLogin !== "true") {
        localStorage.setItem("waitingForLogin", "true");
        onLogInClick();
      }
    }
  }, [isLoggedIn, hasCheckedLoggedIn]);

  return (
    <>
      <AlertComopnent />
      <LandingDiv>
        <ErrorHandler>
          <Suspense>
            <HeaderNew />
          </Suspense>
        </ErrorHandler>
        <Outlet context={{ toShowLogin, toShowLoginUpdater }} />
        {!isHomePage() && (
          <div>
            <Footer />
          </div>
        )}
      </LandingDiv>
    </>
  );
};

export const useShowLoginModal = () => useOutletContext<ContextType>();
