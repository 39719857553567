/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface IPunterSetting {
  settingId: number;
  heading: string;
  settingValue: string;
}

export const SETTING_IDS = {
  totalStakesPaidOut: 1,
  jackpotWinners: 2,
  jackpotPrizes: 3,
  playersDaily: 4,
  totalMeetingsHeld: 5
};

interface ISettings {
  settings: IPunterSetting[];
}

const initialState: ISettings = {
  settings: []
};

const configConst = (state: RootState) => state.config.data;

/**
 * Returns the settings from the PC DB
 */
export const GetSettings: any = createAsyncThunk(
  "GetSettings",
  async (_, { getState }) => {
    const config = configConst(getState() as RootState);
    const response = await axios.get(
      `${config.puntersApi}/api/Settings/GetSettings`
    );

    return response.data.frontEndSettings;
  }
);

const SettingsSlice = createSlice({
  name: "results",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(GetSettings.pending, (state) => {
      state.settings = [];
    });
    builder.addCase(GetSettings.fulfilled, (state, action) => {
      state.settings = action.payload;
    });
    builder.addCase(GetSettings.rejected, (state) => {
      state.settings = [];
    });
  }
});

export default SettingsSlice.reducer;
