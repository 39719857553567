/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const blogBaseUrl = "https://blog.hollywoodbets.net/horse-racing";
const hollywoodBetsBaseUrl = "https://new.hollywoodbets.net";
const puntersChallengeMediaUrl =
  "https://hollywoodmedia.hollywoodbets.net/punterschallengemedia";

export interface IConfig {
  betApi: string | null;
  blogUrl: string | null;
  blogCPTUrl: string | null;
  blogFairviewUrl: string | null;
  blogGautengUrl: string | null;
  blogHongKongUrl: string | null;
  blogKZNUrl: string | null;
  blogSingaporeUrl: string | null;
  cmsPlatformId: string | null;
  facebookUrl: string | null;
  forgotPasswordUrl: string | null;
  gallopTv: string;
  hollywoodBets: string;
  identityUrl: string | null;
  instagramUrl: string | null;
  legacyTokenIssuerApi: string | null;
  puntersApi: string | null;
  puntersHowToPlayVideoUrl: string | undefined;
  redeemVoucherUrl: string | null;
  registerSouthAfricaUrl: string;
  resultsShown: number;
  rotator: string | undefined;
  rotatorMobi: string | undefined;
  twitterUrl: string | null;
  winningForm: string;
  youtubeUrl: string | null;
  showNewBetSlip: boolean;
  showLuckyPick: boolean;
  showFriendsLeague: boolean;
  showMyStats: boolean;
}

const initialState: IConfig = {
  betApi: null,
  blogUrl: null,
  blogCPTUrl: null,
  blogFairviewUrl: null,
  blogGautengUrl: null,
  blogHongKongUrl: null,
  blogKZNUrl: null,
  blogSingaporeUrl: null,
  cmsPlatformId: null,
  facebookUrl: null,
  forgotPasswordUrl: null,
  gallopTv: "",
  hollywoodBets: "",
  identityUrl: null,
  instagramUrl: null,
  legacyTokenIssuerApi: null,
  puntersApi: null,
  puntersHowToPlayVideoUrl: undefined,
  redeemVoucherUrl: null,
  registerSouthAfricaUrl: "",
  resultsShown: 0,
  rotator: undefined,
  rotatorMobi: undefined,
  twitterUrl: null,
  winningForm: "",
  youtubeUrl: null,
  showNewBetSlip: false,
  showLuckyPick: false,
  showFriendsLeague: false,
  showMyStats: false
};

export const loadConfig = createAsyncThunk("config/loadConfig", async () => {
  const response = await fetch("/config.json");

  const releaseConfig = await response.json();

  const tmpConfig = {
    betApi: releaseConfig.PC_BETAPI_URL || process.env.REACT_APP_BETAPI_URL,
    blogUrl: "https://blog.hollywoodbets.net/category/horse-racing",
    blogCPTUrl: `${blogBaseUrl}/winning-form-tips/winning-form-wcp-hollywoodbets-punters-challenge-tips/`,
    blogFairviewUrl: `${blogBaseUrl}/winning-form-ecp-hollywoodbets-punters-challenge-tips/`,
    blogGautengUrl: `${blogBaseUrl}/winning-form-gau-hollywoodbets-punters-challenge-tips/`,
    blogHongKongUrl: `${blogBaseUrl}/winning-form-hollywoodbets-punters-challenge-tips-hong-kong/`,
    blogKZNUrl: `${blogBaseUrl}/winning-form-tips/punters-challenge-tips/`,
    blogSingaporeUrl: `${blogBaseUrl}/winning-form-hollywoodbets-punters-challenge-tips-singapore/`,
    cmsPlatformId:
      releaseConfig.PC_CMS_PLATFORM_ID || process.env.REACT_APP_CMS_PLATFORM_ID,
    facebookUrl: "https://www.facebook.com/hollywoodbets",
    forgotPasswordUrl: `${hollywoodBetsBaseUrl}/forgot-password`,
    gallopTv: releaseConfig.PC_GallopTV || "https://www.galloptv.co.za/",
    hollywoodBets: hollywoodBetsBaseUrl,
    identityUrl:
      releaseConfig.PC_IDENTITY_URL || process.env.REACT_APP_IDENTITY_URL,
    instagramUrl: "https://www.instagram.com/hollywoodbets/",
    legacyTokenIssuerApi:
      releaseConfig.PC_LEGACY_TOKEN_ISSUER_API ||
      process.env.REACT_APP_LEGACY_TOKEN_ISSUER_API,
    puntersApi:
      releaseConfig.PC_PUNTERS_API || process.env.REACT_APP_PUNTERS_API,
    puntersHowToPlayVideoUrl: `${puntersChallengeMediaUrl}/000_PUNTERS_HOW_TO_VIDEO_MAIN_V7.mp4`,
    redeemVoucherUrl: `${hollywoodBetsBaseUrl}/account/deposit`,
    registerSouthAfricaUrl: "https://register.hollywoodbets.net/south-africa/1",
    resultsShown: releaseConfig.PC_RESULTS_SHOWN || 1,
    rotator: process.env.REACT_APP_ROTATOR,
    rotatorMobi: process.env.REACT_APP_ROTATOR_MOB,
    twitterUrl: "https://twitter.com/Hollywoodbets",
    winningForm: "https://future.winningform.co.za/",
    youtubeUrl: "https://www.youtube.com/@hollywoodbets",
    showNewBetSlip: releaseConfig.PC_FEATURE_SHOW_NEW_BET_SLIP,
    showLuckyPick: releaseConfig.PC_FEATURE_SHOW_LUCKY_PICK,
    showFriendsLeague: releaseConfig.PC_FEATURE_SHOW_FRIENDS_LEAGUE,
    showMyStats: releaseConfig.PC_FEATURE_SHOW_MY_STATS
  };

  return tmpConfig;
});

const ConfigSlice = createSlice({
  name: "config",
  initialState: {
    data: initialState
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadConfig.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  }
});

export default ConfigSlice.reducer;
