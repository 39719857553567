/* eslint-disable  @typescript-eslint/no-unused-vars */

import React from "react";

export interface INotificationContextData {
  status: string | null;
  message: string | null;
  duration?: number;
}

export const initdata: INotificationContextData = {
  status: null,
  message: null,
  duration: 2000
};

export const NotificationContext = React.createContext({
  data: initdata,
  notificationUpdater: ({
    status,
    message,
    duration
  }: INotificationContextData) => {}
});

export const STATES = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning"
};
