import React from "react";

export type MediaQueriesContextData = {
  isDesktop: boolean;
};

const initData: MediaQueriesContextData = {
  isDesktop: false
};

export const MediaQueriesContext = React.createContext({
  data: initData
});
