/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import type { RootState } from "../store";

interface ICmsDataItem {
  body: string;
  active: boolean;
  id: number;
  title: string;
}

interface ICmsImageItem {
  imageID: number;
  platformId: number;
  imageTitle: string;
  imageURL: string;
  imageExternalUrl: string;
  target: number;
  altText: string;
  standalone: false;
  isLogo: false;
  active: false;
  imageData: string;
  imageType: string;
  imageGroup: number;
  fileSize: number;
  startDate: string;
  endDate: string;
}

interface IPages {
  currentPage: string;
  cmsData: Record<string, ICmsDataItem> | null;
  cmsErrorText: string | null;
  cmsImages: ICmsImageItem[] | null;
  cmsImagesErrorText: string | null;
  isNavigationInitiated: boolean;
}
const initialState: IPages = {
  currentPage: "/",
  cmsData: null,
  cmsErrorText: null,
  cmsImages: null,
  cmsImagesErrorText: null,
  isNavigationInitiated: false
};

const configConst = (state: RootState) => state.config.data;

export const getCMSData: any = createAsyncThunk(
  "getCMSData",
  async (_, { getState }) => {
    const config = configConst(getState() as RootState);

    const cmsData = await axios.get(
      `${config.puntersApi}/api/CometCms/GetCometCmsDocumentsByPlatformId/${config.cmsPlatformId}`
    );

    return cmsData;
  }
);

export const getCMSImages: any = createAsyncThunk(
  "getCMSImages",
  async (_, { getState }) => {
    const config = configConst(getState() as RootState);

    const cmsImages = await axios.get(
      `${config.puntersApi}/api/CometCms/GetCometCmsImages`
    );
    return cmsImages;
  }
);

const PagesSlice = createSlice({
  name: "Pages",
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setCMSdata: (state, action) => {
      state.cmsData = action.payload;
    },
    setNavigationInitiated: (state, action) => {
      state.isNavigationInitiated = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder.addCase(getCMSData.fulfilled, (state, action) => {
      const { data } = action.payload;

      if (data?.length > 0) {
        const processed: Record<string, ICmsDataItem> = {};

        Object.values(data).forEach((item: any) => {
          processed[item.title] = item as ICmsDataItem;
        });

        state.cmsData = processed;
      }

      console.log("getCMSData.fulfilled state");
    });
    builder.addCase(getCMSData.rejected, (state) => {
      state.cmsErrorText = "Error retriving data";
      console.log("getCMSData.rejected state");
    });

    builder.addCase(getCMSImages.fulfilled, (state, action) => {
      const { data } = action.payload;

      state.cmsImages = [...data];

      console.log("getCMSImages.fulfilled state");
    });
    builder.addCase(getCMSImages.rejected, (state) => {
      state.cmsImagesErrorText = "Error retriving cms images data";
      console.log("getCMSImages.rejected state");
    });
  }
});

export const { setCurrentPage, setCMSdata, setNavigationInitiated } =
  PagesSlice.actions;

export default PagesSlice.reducer;
