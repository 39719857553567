export enum BetType {
  Default = "",
  SingleWin = "Single Wins",
  SinglePlace = "Single Places",
  WinDoubles = "Doubles",
  WinTrebles = "Trebles",
  WinQuads = "Quads",
  AllToCome = "All to Win",
  AllToPlace = "All to Place",
  LP_WinDoubles = "Win Doubles",
  LP_WinPlaceDoubles = "Win/Place Doubles",
  LP_PlaceTrebles = "Place Trebles"
}

export interface ILimitset {
  single: number;
  double: number;
  treble: number;
  quad: number;
  multiple: number;
}

export interface IBetSlipMeeting {
  meetingId: number;
  meetingName: string;
  meetingDate: number | string;
}

export interface IBetSlipRace {
  raceNumber: number;
  horseNumber: number;
  horseName: string;
  odds: number;
  placeOdds: number;
  meetingTime: number | string;
  firstTimer: boolean;
  selected: boolean;
}

export interface IBetSlipLeg {
  race: IBetSlipRace;
  enabled: boolean;
}

export interface IBetSlipBet {
  betType: BetType;
  betCount: number;
  finalOdds: number;
  stakeToBet: number;
  enabled: boolean;
  isFixedOdds: boolean;
  legs: IBetSlipLeg[];
  overLimit: boolean;
}

export interface IBetCombinationInfo {
  betTitle: string;
  combinationBetTypeId: number;
  legsPerBet: number;
  totalStake: number;
  totalOdds: number;
  payout: number;
  guid: string;
  pid: number;
}

export interface IBetslipBetType {
  deductionPercentageUsed: number | null;
  eventBetTypeDetailMapID: number;
  eventDateTime: string;
  eventDetailID: number;
  eventDetailName: string;
  eventDetailNameExtra: string;
  eventDetailNumber: number;
  eventDetailOfferedOdds: number | null;
  eventID: number;
  eventName: string;
  eventNumber: number;
  fK_EventBetTypeMapID: number;
  fK_EventDetailStatusID: number;
  favourite: boolean | null;
  finishingPosition: number | null;
  firstTimer: boolean | null;
}

export interface IBetDataDTO {
  combinatonInfo: IBetCombinationInfo;
  dt: IBetslipBetType[];
}

export interface IBetStrikeRetryRequest {
  betRequests: IBetDataDTO[];
  totalBets: number;
  isLuckyPick: boolean;
}

export interface IBetRequestInfo {
  request: IBetDataDTO;
  failureReason: string;
}

export interface IBetStrikeResponse {
  failedBetRequests: IBetRequestInfo[] | null;
  totalSuccessfulBetsPlaced: number;
  totalBets: number;
  isLuckyPick: boolean;
  responseStatus: number;
  responseMessage: string;
  responseMessageDetail: string;
}

export interface IBetSlipData {
  meeting: IBetSlipMeeting;
  races: IBetSlipRace[];
  bets: IBetSlipBet[];
  totalStake: number;
  totalReturn: number;
  betStrikeResponse: IBetStrikeResponse | null;
  forcedSP: boolean;
  limits: { [Key: string]: ILimitset };
  allowBet: boolean;
}
