import { useCallback, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { getCookie, setCookie } from "../utils/cookieUtils";
import {
  attemptPersistedLogin,
  getLegacyAccessToken,
  puntersChallengeLogin,
  setUserName
} from "../store/features/userSlice";

const useLogin = () => {
  const auth = useAuth();

  const userData = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  const signoutRedirect = useCallback(() => {
    auth
      .signoutRedirect()
      .then((val) => val)
      .catch((err) => {
        // eslint-disable-next-line
        console.error(err);
      });
  }, [auth]);

  const checksPostSSOAuthentication = () => {
    if (
      auth?.isAuthenticated &&
      auth?.user &&
      !auth?.isLoading &&
      !auth?.user.expired &&
      !userData?.isLoggedIn
    ) {
      const LoggedinCookie = getCookie("Loggedin");
      const storedPCData = localStorage.getItem("rememberpc");
      const persisted = LoggedinCookie && storedPCData;
      const isInLoginprocess = getCookie("isInLoginprocess");

      if (isInLoginprocess && !persisted) {
        dispatch(setUserName(auth.user.profile.sub));

        if (!LoggedinCookie) {
          const expiryDate = new Date();
          expiryDate.setTime(expiryDate.getTime() + 43200 * 1000);

          setCookie(
            "Loggedin",
            "true",
            expiryDate,
            "/",
            window.location.hostname
          );
        }

        dispatch(
          getLegacyAccessToken({ ssoAccessToken: auth.user.access_token })
        );
      }
    }
  };

  useEffect(() => {
    dispatch(attemptPersistedLogin({}));
  }, []);

  useEffect(() => {
    if (
      userData.legacyAccessToken &&
      userData.userName &&
      !userData.isPending &&
      !userData.isLoggedIn
    ) {
      dispatch(puntersChallengeLogin({ userName: userData.userName }));
    }
  }, [userData.legacyAccessToken, userData.userName]);

  useEffect(() => {
    if (auth) {
      checksPostSSOAuthentication();
    }
  }, [auth]);

  const onLogInClick = () => {
    const expiryDate = new Date();
    expiryDate.setTime(
      expiryDate.getTime() + (auth.isAuthenticated ? 2000 : 120000)
    );

    setCookie(
      "isInLoginprocess",
      "true",
      expiryDate,
      "/",
      window.location.hostname
    );

    if (auth?.isAuthenticated) {
      checksPostSSOAuthentication();
    } else {
      auth
        .signinRedirect()
        .then((val) => val)
        .catch((err) => {
          // eslint-disable-next-line
          console.error(err);
        });
    }
  };

  useEffect(() => {
    if (auth) {
      checksPostSSOAuthentication();
    }
  }, [auth]);

  return { onLogInClick, signoutRedirect };
};

export default useLogin;
