/* eslint-disable  react/no-danger */
import Container, { type IContainer } from "../bricks/container";

interface IBoxedText extends IContainer {
  text?: string;
  htmltext?: string;
  parastyle?: object;
}

const BoxedText = (props: IBoxedText) => {
  const { text, htmltext, parastyle } = props;
  return (
    <Container {...props}>
      {text && <p style={parastyle}>{text} </p>}
      {htmltext && <p dangerouslySetInnerHTML={{ __html: htmltext }} />}
    </Container>
  );
};

export default BoxedText;
