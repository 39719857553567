/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice
} from "@reduxjs/toolkit";
import axios from "axios";
import type { RootState } from "../store";

const configConst = (state: RootState) => state.config.data;
/**
 *  Returns the meeting id that is needed for the following calls.
 */
export const GetLeaderboardMeetingIdMetaData: any = createAsyncThunk(
  "GetLeaderboardMeetingIdMetaData",
  async (_, { getState }) => {
    const config = configConst(getState() as RootState);
    const data = await axios.get(
      `${config.puntersApi}/api/Meeting/GetLeaderboardMeetingId`
    );
    return data.data;
  }
);

export interface IPunterQueryPageLeanInputData {
  punterId: string | number;
  meetingId: string | number;
}

interface IPunterQueryPageInputData {
  meetingId: string | number;
  pageId: string | number;
  numberPerPage: string | number;
  searchFor: "";
}
/**
 * Returns the leaders ,10 in number, users rank is added at the end.
 */
export const GetLeaderboardForMeeting: any = createAsyncThunk(
  "GetLeaderboardForMeeting",
  async (
    { punterId, meetingId }: IPunterQueryPageLeanInputData,
    { getState }
  ) => {
    const config = configConst(getState() as RootState);
    const data = await axios.get(
      `${config.puntersApi}/api/Leaderboard/GetDailyLeaderboard/${
        punterId || 0
      }/${meetingId}`
    );

    return { data: data.data, meetingid: meetingId };
  }
);

/**
 * Returns the selected punters selection.
 * */
export const GetLeaderboardPuntersSelection: any = createAsyncThunk(
  "GetLeaderboardPuntersSelection",
  async (
    { punterId, meetingId }: IPunterQueryPageLeanInputData,
    { getState }
  ) => {
    const config = configConst(getState() as RootState);

    const data = await axios.get(
      `${config.puntersApi}/api/Selection/GetPunterSelection/${punterId}/${meetingId}`
    );

    return data.data;
  }
);

/**
 * Returns the next leaders ,10 in number.
 */
export const GetLeaderboardForMeetingWithPage: any = createAsyncThunk(
  "GetLeaderboardForMeetingWithPage",
  async (
    { pageId, meetingId, numberPerPage, searchFor }: IPunterQueryPageInputData,
    { getState }
  ) => {
    const config = configConst(getState() as RootState);

    const data = await axios.get(
      `${
        config.puntersApi
      }/api/Leaderboard/GetDailyLeaderboardPaged/${pageId}/${numberPerPage}/${meetingId}?username=${
        searchFor || ""
      }`
    );

    return { pagenumber: pageId, data: data.data, meetingid: meetingId };
  }
);

/**
 * Returns the selected punters selection.
 * */
export const GetOwnSelection: any = createAsyncThunk(
  "GetOwnSelection",
  async (
    { punterId, meetingId }: IPunterQueryPageLeanInputData,
    { getState }
  ) => {
    const config = configConst(getState() as RootState);

    const data = await axios.get(
      `${config.puntersApi}/api/Selection/GetPunterSelection/${punterId}/${meetingId}`
    );

    return data.data;
  }
);

export interface ICurrentMeetingMetaData {
  competitionId: string | number;
  meetingDate: string | number;
  meetingId: string | number;
  meetingStatusId: string | number;
  raceCourseId: string | number;
  raceTrackAssetId: string | number;
}

export interface ILBWorkingsMetaData {
  meetingId: number | string;
  meetingDate: number | string;
  meetingStatusId: number | string;
  raceCourseName: string;
  raceTrackAssetId: number | string;
}

export interface ILeaderboardPuntersOwnSelectionUnit {
  horseName: string;
  points: string | number;
  raceNumber: string | number;
  saddle: string | number;
  racePosition?: number;
}
interface ILeaderboardPunterSelection {
  selections: ILeaderboardPuntersOwnSelectionUnit[];
  meetingId: string | number;
  punterId: string | number;
  userId: string | number;
}
export interface ILeaderboardPunterUnit {
  punterId: string | number;
  userId: string | number;
  rank: string | number;
  total: string | number;
  bonus: string | number;
  racePoints: string | number;
  winners: string | number;
  places: string | number;
  totalPunters: string | number;
  date: string | number;
  totalSelections: string | number;
  secondPlace: string | number;
  thirdPlace: string | number;
  selections: ILeaderboardPunterSelection[];
}

export interface IQuickResultItem {
  name: string;
  rank: number;
  points: number;
  meetingId: number;
  meetingDate: string;
}

interface ILeaderboardSlice {
  lbMeetingsMetaData: ICurrentMeetingMetaData | null;
  lbWorkingMetaData: ILBWorkingsMetaData[] | null;
  currentMeetingId: string | number;
  currentPage: number;
  puntersinMeetingsWithPage: Record<
    string | number,
    Record<string | number, ILeaderboardPunterUnit[]>
  >;
  currentPuntersList: ILeaderboardPunterUnit[] | null;
  totalPuntersWRTMeetings: Record<string | number, number>;
  puntersInPage: Record<number, ILeaderboardPunterUnit[]>;
  puntersSelections: Record<string | number, ILeaderboardPunterSelection>;
  totalPunters: number;
  ownSelection: ILeaderboardPunterSelection | null;
  error: string;
  quickResults: IQuickResultItem[] | null;
  isFetching: boolean;
  meetingIdToDataMap: {
    [meeingId: string]: {
      punters: ILeaderboardPunterUnit[];
      totalPunters: number;
    };
  };
}

const initialState: ILeaderboardSlice = {
  lbMeetingsMetaData: null,
  lbWorkingMetaData: null,
  currentPuntersList: null,
  currentPage: 1,
  puntersInPage: {},
  puntersSelections: {},
  totalPunters: 0,
  ownSelection: null,
  error: "",
  puntersinMeetingsWithPage: {},
  currentMeetingId: "",
  totalPuntersWRTMeetings: {},
  quickResults: [],
  isFetching: false,
  meetingIdToDataMap: {}
};

const LeaderboardSlice = createSlice({
  name: "Leaderboard",
  initialState,
  reducers: {
    setQuickResults: (state, action) => {
      state.quickResults = action.payload;
    },
    setCurrentMeetingId: (state, action) => {
      state.currentMeetingId = action.payload;
    },
    clearPuntersList: (state) => {
      state.currentPuntersList = null;
    },
    setLeaderboardData: (state, action) => {
      state.lbWorkingMetaData = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(GetLeaderboardMeetingIdMetaData.pending, (state) => {
      state.currentPuntersList = null;
      state.currentMeetingId = "";
      console.log("GetLeaderboardMeetingId.pending state");
    });

    builder.addCase(
      GetLeaderboardMeetingIdMetaData.fulfilled,
      (state, action) => {
        state.lbMeetingsMetaData = action.payload.meeting;

        console.log("GetLeaderboardMeetingId.fulfilled state");
      }
    );
    builder.addCase(
      GetLeaderboardMeetingIdMetaData.rejected,
      (state, action) => {
        state.error = action.error.message;

        console.log(
          "GetLeaderboardMeetingIdMetaData.rejected state ,reason = ",
          action.error.message
        );
      }
    );

    //  fisrt leaderboard punters call

    builder.addCase(GetLeaderboardForMeeting.pending, (state) => {
      state.isFetching = true;
      state.currentPuntersList = null;
      console.log("GetLeaderboardForMeeting.pending state");
    });

    builder.addCase(GetLeaderboardForMeeting.fulfilled, (state, action) => {
      const { data, meetingid } = action.payload;
      const { punters, totalPunters } = data.dailyLeaderboard;

      if (!state.meetingIdToDataMap) state.meetingIdToDataMap = {};

      state.meetingIdToDataMap[meetingid] = { punters, totalPunters };

      state.totalPuntersWRTMeetings[meetingid] = totalPunters;

      state.currentMeetingId = meetingid;
      state.currentPage = 1;
      if (!state.puntersinMeetingsWithPage[meetingid])
        state.puntersinMeetingsWithPage[meetingid] = {};
      state.puntersinMeetingsWithPage[meetingid][state.currentPage] = [
        ...punters
      ];
      state.currentPuntersList = [...punters];
      state.isFetching = false;

      console.log("GetLeaderboardForMeeting.fulfilled state");
    });
    builder.addCase(GetLeaderboardForMeeting.rejected, (state, action) => {
      state.error = action.error.message;

      console.log(
        "GetLeaderboardForMeeting.rejected state ,reason = ",
        action.error.message
      );
    });

    //  page wise punters

    builder.addCase(GetLeaderboardForMeetingWithPage.pending, (state) => {
      state.isFetching = true;
      console.log("GetLeaderboardForMeetingWithPage.pending state");
    });

    builder.addCase(
      GetLeaderboardForMeetingWithPage.fulfilled,
      (state, action) => {
        const { pagenumber, data, meetingid } = action.payload;

        const newPunters = data.dailyLeaderboard.punters;
        state.currentMeetingId = meetingid;
        state.currentPage = pagenumber;
        if (!state.puntersinMeetingsWithPage[meetingid])
          state.puntersinMeetingsWithPage[meetingid] = {};
        state.puntersinMeetingsWithPage[meetingid][state.currentPage] = [
          ...newPunters
        ];
        state.currentPuntersList = [...newPunters];

        state.isFetching = false;

        console.log("GetLeaderboardForMeetingWithPage.fulfilled state");
      }
    );
    builder.addCase(
      GetLeaderboardForMeetingWithPage.rejected,
      (state, action) => {
        state.error = action.error.message;

        console.log(
          "GetLeaderboardForMeetingWithPage.rejected state ,reason = ",
          action.error.message
        );
      }
    );
    //  punter selection

    builder.addCase(GetLeaderboardPuntersSelection.pending, () => {
      console.log("GetLeaderboardForMeetingWithPage.pending state");
    });

    builder.addCase(
      GetLeaderboardPuntersSelection.fulfilled,
      (state, action) => {
        const { selection } = action.payload;
        state.puntersSelections[selection.punterId] = selection.selections;

        console.log("GetLeaderboardPuntersSelection.fulfilled state");
      }
    );
    builder.addCase(
      GetLeaderboardPuntersSelection.rejected,
      (state, action) => {
        state.error = action.error.message;

        console.log(
          "GetLeaderboardForMeetingWithPage.rejected state ,reason = ",
          action.error.message
        );
      }
    );
    //  own selection

    builder.addCase(GetOwnSelection.pending, () => {
      console.log("GetOwnSelection.pending state");
    });

    builder.addCase(GetOwnSelection.fulfilled, (state, action) => {
      const { selection } = action.payload;
      state.ownSelection = selection;

      console.log("GetOwnSelection.fulfilled state");
    });
    builder.addCase(GetOwnSelection.rejected, (state, action) => {
      state.error = action.error.message;

      console.log(
        "GetOwnSelection.rejected state ,reason = ",
        action.error.message
      );
    });
  }
});

export const CurrentLeaderboardPage = createDraftSafeSelector(
  (state: RootState) => state.leaderboard,
  (data) => data.currentPage
);

export const {
  setQuickResults,
  setCurrentMeetingId,
  clearPuntersList,
  setLeaderboardData
} = LeaderboardSlice.actions;
export default LeaderboardSlice.reducer;
