import GallopLogo from "@assets/galloptv-logo.svg";
import HollywoodLogo from "@assets/hwb-logo.svg";
import winningformLogo from "@assets/winningform-logo.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useContext } from "react";
import Container from "../components/bricks/container";
import BoxedText from "../components/composites/boxedText";
import CustomerSupport from "../components/views/customerSupport";
import Logo from "../components/views/logo";
import Social from "../components/views/social";
import FlexContainer from "../components/bricks/flexContainer";
import openLink from "../utils/pageUtils";
import type { RootState } from "../store/store";
import { MediaQueriesContext } from "../contexts/mediaQueriesContext";
import {
  DoMoreFlexContainer,
  GallopTvDiv,
  HWBLogoDiv,
  HeaderUsefulLinks,
  UsefulLinksHeaderContainerMob,
  UsefulLinksHeaderMob,
  UsefulLinksItemLabel,
  WinningFormLogoDiv
} from "./footer.style";
import theme from "../theme";

const Footer = () => {
  const config = useSelector((state: RootState) => state.config.data);

  const { isDesktop } = useContext(MediaQueriesContext).data;

  const navigate = useNavigate();

  const onUsefulLinkClick = (path: string) => {
    navigate(path);

    window.scrollTo({ top: 0 });
  };

  return !isDesktop ? (
    <FlexContainer
      direction="column"
      alignitems="left"
      backgroundImage={`linear-gradient(${theme.colors.purpleSecondary}, ${theme.colors.purplePrimary})`}
      paddingleft="25px"
      paddingright="25px"
      paddingtop="15px">
      <CustomerSupport />
      <UsefulLinksHeaderContainerMob>
        <UsefulLinksHeaderMob>Useful Links</UsefulLinksHeaderMob>
        <UsefulLinksItemLabel
          onClick={() => {
            onUsefulLinkClick("termsandconditions");
          }}>
          Terms & Conditions
        </UsefulLinksItemLabel>
      </UsefulLinksHeaderContainerMob>
      <FlexContainer
        width="100%"
        height="5px"
        bgcolor="none"
        alignitems="center"
        justifycontent="center"
        margintop="17px">
        <Container width="100%" height="1px" bgcolor="#86868d" />
      </FlexContainer>
      <Container margintop="5px" bgcolor="none">
        <Social />
      </Container>
      <Container
        bgcolor="none"
        marginleft="5px"
        width="90%"
        textalign="left"
        paddingtop="15px">
        <Logo />
      </Container>
      <BoxedText
        marginleft="0%"
        width="100%"
        bgcolor="none"
        boxsizing="true"
        color={`${theme.colors.primary}`}
        fontSize="0.6rem"
        padding="0px"
        htmltext="Hollywood Sportsbook is a licensed betting operator. Hollywoodbets supports responsible gambling.
No persons under the age of 18 are permitted to gamble. <br></br> Winners know when to stop. South African responsible gambling foundation toll-free counselling line
0800 006 008 or WhatsApp help to 076 675 0710."
      />
    </FlexContainer>
  ) : (
    <FlexContainer
      direction="column"
      bgcolor="linear-gradient(#5C2D91,#764CBF)">
      <FlexContainer
        id="h-flex-4-children"
        bgcolor="none"
        justifycontent="flex-start">
        <Container
          id="h-flex-4-child1"
          bgcolor="none"
          marginleft="14px"
          width="25%"
          paddingtop="30px">
          <Logo width="80%" />
        </Container>
        <Container
          id="h-flex-4-child2"
          width="30%"
          bgcolor="none"
          paddingtop="27px"
          display="grid"
          placecontent="center">
          <CustomerSupport />
        </Container>

        <FlexContainer
          id="h-flex-4-child3_useful_links"
          rowgap="15px"
          direction="column"
          paddingtop="27px"
          width="15%"
          color="#fff"
          fontSize="0.7rem"
          alignitems="left">
          <HeaderUsefulLinks> Useful Links</HeaderUsefulLinks>
          <UsefulLinksItemLabel
            onClick={() => {
              onUsefulLinkClick("learntoplay");
            }}>
            {" "}
            How To Play
          </UsefulLinksItemLabel>
          <UsefulLinksItemLabel
            onClick={() => {
              onUsefulLinkClick("faqpage");
            }}>
            {" "}
            FAQ
          </UsefulLinksItemLabel>
          <UsefulLinksItemLabel
            onClick={() => {
              onUsefulLinkClick("paia");
            }}>
            {" "}
            PAIA Manual
          </UsefulLinksItemLabel>
          <UsefulLinksItemLabel
            onClick={() => {
              onUsefulLinkClick("termsandconditions");
            }}>
            {" "}
            Terms & Conditions
          </UsefulLinksItemLabel>
        </FlexContainer>

        <FlexContainer
          id="h-flex-4-child4"
          direction="column"
          width="30%"
          bgcolor="none"
          color="#fff"
          rowgap="10px"
          paddingtop="27px"
          fontSize="0.7rem"
          alignitems="left"
          paddingleft="3%">
          <HeaderUsefulLinks> Do More</HeaderUsefulLinks>
          <DoMoreFlexContainer id="icons">
            <FlexContainer
              id="galloptv"
              direction="column"
              alignitems="left"
              width="30%"
              rowgap="5px">
              <UsefulLinksItemLabel>Watch Now! </UsefulLinksItemLabel>
              <GallopTvDiv
                onClick={() => {
                  openLink(`${config.gallopTv}`);
                }}
                src={GallopLogo}
              />
            </FlexContainer>

            <FlexContainer
              id="hwlogo"
              direction="column"
              alignitems="left"
              width="30%"
              rowgap="5px">
              <UsefulLinksItemLabel>Place a Bet! </UsefulLinksItemLabel>
              <HWBLogoDiv
                onClick={() => {
                  openLink(`${config.hollywoodBets}`);
                }}
                src={HollywoodLogo}
              />
            </FlexContainer>

            <FlexContainer
              id="hwlogo"
              direction="column"
              alignitems="left"
              width="30%"
              rowgap="5px">
              <UsefulLinksItemLabel>Get insights! </UsefulLinksItemLabel>
              <WinningFormLogoDiv
                onClick={() => {
                  openLink(`${config.winningForm}`);
                }}
                src={winningformLogo}
              />
            </FlexContainer>
          </DoMoreFlexContainer>

          <Container width="200px" marginleft="0px">
            <Social />
          </Container>
        </FlexContainer>
      </FlexContainer>

      <BoxedText
        bgcolor="none"
        boxsizing="true"
        color="#fff"
        fontSize="0.6rem"
        padding="10px"
        htmltext="Hollywood Sportsbook is a licensed betting operator. Hollywoodbets supports responsible gambling.
No persons under the age of 18 are permitted to gamble. <br></br> Winners know when to stop. South African responsible gambling foundation toll-free counselling line
0800 006 008 or WhatsApp help to 076 675 0710."
      />
    </FlexContainer>
  );
};

export default Footer;
