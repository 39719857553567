import { useState, useMemo } from "react";
import {
  INotificationContextData,
  NotificationContext,
  initdata
} from "../../contexts/notificationContext";

const NotificationProvider = ({ children }: any) => {
  const [notification, setNotification] =
    useState<INotificationContextData>(initdata);

  const updater = ({ status, message, duration }: INotificationContextData) => {
    setNotification({ status, message, duration });
  };

  const ref = useMemo(
    () => ({ data: notification, notificationUpdater: updater }),
    [notification]
  );

  return (
    <NotificationContext.Provider value={ref}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
