import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from "react-router-dom";
import { lazy, Suspense } from "react";
import { Landing } from "./pages/landing";
import Notfound from "./pages/notfound";
import ProtectedRoutes from "./protectedroutes";
import LazyFallback from "./lazy/lazyFallback";

const HomeNew = lazy(() => import("./pages/homeNew"));
const Leaderboard = lazy(() => import("./pages/leaderboard"));
const Results = lazy(() => import("./pages/results"));
const LearnToPlay = lazy(() => import("./pages/learnToPlay"));
const TermsAndConditions = lazy(() => import("./pages/termsAndConditions"));
const FaqPage = lazy(() => import("./pages/faqPage/faqpage"));
const Paiamanual = lazy(() => import("./pages/paiamanual"));
const FriendsLeague = lazy(() => import("./pages/friendsLeague"));
const MyStats = lazy(() => import("./pages/myStats"));

const App = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<Landing />}>
          <Route
            index
            element={
              <Suspense fallback={<LazyFallback text="Loading Home" />}>
                <HomeNew />
              </Suspense>
            }
          />
          <Route
            path="home"
            element={
              <Suspense fallback={<LazyFallback text="Loading Home" />}>
                <HomeNew />
              </Suspense>
            }
          />
          <Route element={<ProtectedRoutes />}>
            <Route
              path="results"
              element={
                <Suspense fallback={<LazyFallback text="Loading Results" />}>
                  <Results />
                </Suspense>
              }
            />
            <Route
              path="friendsChallenge"
              element={
                <Suspense
                  fallback={<LazyFallback text="Loading Friends Challenge" />}>
                  <FriendsLeague />
                </Suspense>
              }
            />
            <Route
              path="mystats"
              element={
                <Suspense fallback={<LazyFallback text="Loading My Stats" />}>
                  <MyStats />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="leaderboard"
            element={
              <Suspense fallback={<LazyFallback text="Loading leaderboard" />}>
                <Leaderboard />
              </Suspense>
            }
          />
          <Route
            path="learntoplay"
            element={
              <Suspense
                fallback={<LazyFallback text="Loading Learn To Play" />}>
                <LearnToPlay />
              </Suspense>
            }
          />
          <Route
            path="termsandconditions"
            element={
              <Suspense>
                <TermsAndConditions />
              </Suspense>
            }
          />
          <Route
            path="faqpage"
            element={
              <Suspense fallback={<LazyFallback text="Loading FAQ" />}>
                <FaqPage />
              </Suspense>
            }
          />
          <Route
            path="paia"
            element={
              <Suspense fallback={<LazyFallback text="Loading PAIA" />}>
                <Paiamanual />
              </Suspense>
            }
          />
        </Route>
        <Route path="*" element={<Notfound />} />
      </>
    )
  );
  return <RouterProvider router={router} />;
};

export default App;
