import FlagHongKong from "@assets/flag-hong-kong.svg";
import FlagSingapore from "@assets/flag-singapore.svg";
import FlagSouthAfrica from "@assets/flag-south-africa.svg";
import { IMeetingMetaData } from "../store/features/meetingsSlice";
import { ILBWorkingsMetaData } from "../store/features/leaderboardSlice";

export const NON_SA_MEETINGS = ["Sha Tin", "Happy Valley", "Kranji"];

const sortRacesByDate = (
  races: IMeetingMetaData[] | ILBWorkingsMetaData[]
): IMeetingMetaData[] | ILBWorkingsMetaData[] => {
  return races
    .slice()
    .sort((a, b) => new Date(a.meetingDate) - new Date(b.meetingDate));
};

export const sortRacesWithSAPriority = (
  races: IMeetingMetaData[] | ILBWorkingsMetaData[]
): IMeetingMetaData[] | ILBWorkingsMetaData[] => {
  const southAfricanRaces: Array<IMeetingMetaData | ILBWorkingsMetaData> = [];
  const nonSouthAficanRaces: Array<IMeetingMetaData | ILBWorkingsMetaData> = [];

  races.forEach((race) => {
    if (
      race.raceCourseName === "Sha Tin" ||
      race.raceCourseName === "Happy Valley" ||
      race.raceCourseName === "Kranji"
    ) {
      nonSouthAficanRaces.push(race);
    } else {
      southAfricanRaces.push(race);
    }
  });

  return sortRacesByDate(southAfricanRaces.concat(nonSouthAficanRaces));
};

export const getRaceCourseCountryFlag = (raceCourseName: string) => {
  let countryFlag = FlagSouthAfrica;

  if (raceCourseName === "Sha Tin" || raceCourseName === "Happy Valley") {
    countryFlag = FlagHongKong;
  } else if (raceCourseName === "Kranji") {
    countryFlag = FlagSingapore;
  }

  return countryFlag;
};

export const isNonSAMeeting = (meetingName: string) => {
  return NON_SA_MEETINGS.includes(meetingName);
};

export const isSAMeeting = (meetingName: string) => {
  return !NON_SA_MEETINGS.includes(meetingName);
};

export default sortRacesWithSAPriority;
