export const getCookie = (name: string): string | undefined => {
  const decodedCookie = decodeURIComponent(document.cookie);
  const keyValuePairList = decodedCookie.split("; ");
  const keyValuepair = keyValuePairList.find((item) => item.startsWith(name));
  const val = keyValuepair?.split("=")[1];

  return val;
};

export const setCookie = (
  id: string,
  value: any,
  expiryDate: Date,
  path?: string,
  domain?: string
): void => {
  let formatted = `${id}=${value}`;
  if (expiryDate) formatted += `; expires=${expiryDate.toUTCString()}`;
  if (path) formatted += `; path=${path}`;
  if (domain) formatted += `; domain=${domain}`;

  document.cookie = formatted;
};

export const clearCookie = (id: string, domain?: string): void => {
  document.cookie = `${id}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=${
    domain ?? window.location.hostname
  }`;
};
