import { ErrorBoundary } from "react-error-boundary";

type ErrorHandlerType = {
  children: any;
  fallbackText?: string;
  fallbackRenderer?: any;
};

const ErrorHandler = (props: ErrorHandlerType) => {
  const { children, fallbackText, fallbackRenderer } = props;

  const getProps = () => {
    if (fallbackRenderer) return { fallbackRender: fallbackRenderer };

    return { fallback: fallbackText ? <div>{fallbackText}</div> : null };
  };

  return <ErrorBoundary {...getProps()}>{children}</ErrorBoundary>;
};

export default ErrorHandler;
