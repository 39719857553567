import styled from "styled-components";
import theme from "../../theme";

export const CustomerSupportEmailHeader = styled.span`
  margin: 0;
  color: ${theme.colors.customerSupportColor};
  line-height: 1.3;
`;

export const CustomerSupportLinkContainer = styled.span`
  margin: 0;
  line-height: 1.3;
  font-weight: bold;
`;

export const CustomerSupportLink = styled.a`
  text-decoration: none;
  color: ${theme.colors.primary};
`;
