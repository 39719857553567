import { BetType, IBetSlipBet } from "../betslip.models";

export const isAllToBet = (bet: IBetSlipBet): boolean => {
  return (
    bet.betType === BetType.AllToCome || bet.betType === BetType.AllToPlace
  );
};

export const isSingleBet = (betType: BetType): boolean =>
  betType === BetType.SinglePlace || betType === BetType.SingleWin;
